exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-505-js": () => import("./../../../src/pages/505.js" /* webpackChunkName: "component---src-pages-505-js" */),
  "component---src-pages-basic-dcma-js": () => import("./../../../src/pages/basic/dcma.js" /* webpackChunkName: "component---src-pages-basic-dcma-js" */),
  "component---src-pages-basic-privacy-policy-js": () => import("./../../../src/pages/basic/privacy-policy.js" /* webpackChunkName: "component---src-pages-basic-privacy-policy-js" */),
  "component---src-pages-basic-term-condition-js": () => import("./../../../src/pages/basic/term-condition.js" /* webpackChunkName: "component---src-pages-basic-term-condition-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-movies-watch-online-js": () => import("./../../../src/pages/movies/watch-online.js" /* webpackChunkName: "component---src-pages-movies-watch-online-js" */),
  "component---src-pages-nowplaying-movies-online-free-js": () => import("./../../../src/pages/nowplaying-movies-online-free.js" /* webpackChunkName: "component---src-pages-nowplaying-movies-online-free-js" */),
  "component---src-pages-popular-movies-online-free-js": () => import("./../../../src/pages/popular-movies-online-free.js" /* webpackChunkName: "component---src-pages-popular-movies-online-free-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-toprated-movies-online-free-js": () => import("./../../../src/pages/toprated-movies-online-free.js" /* webpackChunkName: "component---src-pages-toprated-movies-online-free-js" */),
  "component---src-pages-tvshow-watch-online-js": () => import("./../../../src/pages/tvshow/watch-online.js" /* webpackChunkName: "component---src-pages-tvshow-watch-online-js" */),
  "component---src-pages-upcoming-movies-online-free-js": () => import("./../../../src/pages/upcoming-movies-online-free.js" /* webpackChunkName: "component---src-pages-upcoming-movies-online-free-js" */),
  "component---src-pages-walt-disney-movies-online-js": () => import("./../../../src/pages/walt-disney-movies-online.js" /* webpackChunkName: "component---src-pages-walt-disney-movies-online-js" */),
  "component---src-templates-category-item-js": () => import("./../../../src/templates/category-item.js" /* webpackChunkName: "component---src-templates-category-item-js" */),
  "component---src-templates-movie-item-js": () => import("./../../../src/templates/movie-item.js" /* webpackChunkName: "component---src-templates-movie-item-js" */),
  "component---src-templates-season-item-js": () => import("./../../../src/templates/season-item.js" /* webpackChunkName: "component---src-templates-season-item-js" */),
  "component---src-templates-tag-item-js": () => import("./../../../src/templates/tag-item.js" /* webpackChunkName: "component---src-templates-tag-item-js" */),
  "component---src-templates-tvshow-item-js": () => import("./../../../src/templates/tvshow-item.js" /* webpackChunkName: "component---src-templates-tvshow-item-js" */)
}

